export function getReadableEventTarget(target) {
    switch (target) {
        case "cys":
            return "CYS Members";
        case "rsy":
            return "School Members (RSY & Other)";
        case "cyt":
            return "CYT Members";
        case "teacher":
            return "Teacher Members (RTY, E-RTY & Other)";
        case "annual":
            return "Annual Members";
        default:
            return "All Members";
    }
}