<template>
  <div>
    <b-container class="" style="min-height:500px;background-color:#fff">
      <div v-if="loading === false" class="col-12 mt-5">
          <h4 class="text-uppercase text-center mb-4">{{ $t('Grow Your Yoga Career with World Yoga Alliance Community Events!') }}</h4>
          <p>{{ $t('At World Yoga Alliance (WYA), we believe that sharing is caring—and what better way to share your passion for yoga than by joining our exclusive Community Events? These events are designed to help you expand your yoga career, business, and credentials while connecting with a global network of like-minded practitioners.') }}</p>
          <p>{{ $t('Our WYA Community Events offer unique opportunities for personal and professional growth. Some events are exclusive to WYA Community Members, providing specialized training, networking sessions, and business-building strategies. Others are open to the public, allowing everyone—regardless of their background—to experience the transformative power of yoga.') }}</p>
          <p>{{ $t('By participating, you can:') }}</p>
          <ul class="list-unstyled">
            <li>{{ $t('✔ Enhance Your Credentials – Gain recognition and grow your professional standing.') }}</li>
            <li>{{ $t('✔ Expand Your Network – Connect with experienced yoga teachers, schools, and wellness experts.') }}</li>
            <li>{{ $t('✔ Discover New Opportunities – Find collaborations, teaching opportunities, and business growth strategies.') }}</li>
            <li>{{ $t('✔ Deepen Your Practice – Learn from master trainers and experienced yogis worldwide.') }}</li>
          </ul>
          <p>{{ $t('Ready to take your yoga journey to the next level? Join the WYA Community today and unlock access to exclusive learning experiences that will shape your future in yoga.') }}</p>
          <p>{{ $t('Register Now and be part of a thriving international yoga network!') }}</p>
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br />
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mb-5 pt-4">
        <div class="mt-5">
          <h1 class="text-uppercase text-center">{{ $t('Upcoming Events') }}</h1>
          <b-row v-if="events.length > 0" class="mt-5">
            <b-col v-for="(event, index) in events" :key="index" cols="12" sm="12" class="mb-5">
              <b-row>
                <b-col cols="1" sm="1">
                  <h5 class="font-weight-bold">{{ event.month }}</h5>
                  <h2 class="font-weight-bold">{{ event.day }}</h2>
                </b-col>
                <b-col cols="12" sm="7">
                  <b-img class="w-100" :src="event.image" />
                </b-col>
                <b-col cols="12" sm="4">
                  <p class="font-weight-bold mb-0">{{ event.subject }}</p>
                  <p class="mb-0"><strong>{{ $t('Start at:') }}</strong> {{ new Date(event.start_date) | dateFormat('DD MMM YYYY') }} {{ event.start_time?.split(":")?.slice(0, 2)?.join(":") }}</p>
                  <p class="mb-0"><strong>{{ $t('End at:') }}</strong> {{ new Date(event.end_date) | dateFormat('DD MMM YYYY') }} {{ event.end_time?.split(":")?.slice(0, 2)?.join(":") }}</p>
                  <p class="text-primary-light my-4" v-if="event.description"><i>{{ event.description }}</i></p>
                  <div v-if="!loggedIn" class="mt-5 text-center">
                    <p class="mb-0">{{ $t('This content is restricted to WYA Members Only.') }}</p>
                    <p>{{ $t('Please') }} <router-link :to="{ name: 'authenLogin' }">{{ $t('login') }}</router-link> {{ $t('to access.') }}</p>
                    <p class="mb-0">{{ $t('Not a Member, yet?') }}</p>
                    <b-button pill :to="{ name: 'authenSignup' }" class="px-5 mt-1 font-weight-bold btn-danger-light">{{ $t('Sign Up Now') }}</b-button>
                  </div>
                  <div v-else-if="loggedIn" class="mt-5 text-center">
                    <!-- <p class="mb-2" v-if="event.google_meet"><strong>{{ $t('Conference Link:') }}</strong> <a :href="event.google_meet" target="_blank" rel="nofollow">{{ event.google_meet }}</a></p> -->
                    <p class="mb-2" v-if="event.email_target"><strong>{{ $t('Eligible for:') }}</strong> {{ $t(getReadableEventTarget(event.email_target)) }}</p>
                    <p class="mb-0">
                        <b-button pill class="px-5 mt-1 font-weight-bold btn-danger-light" @click="applyEvent(event)" :disabled="event.already_apply === true || event.can_apply !== true">{{ event.already_apply === true ? $t('Already Applied') : $t('Apply Now') }}</b-button>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col class="text-center text-muted">
              <p>{{ $t('No upcoming events available.') }}</p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Api from '../../api/api';
import { getReadableEventTarget } from '../../utils/event';

export default {
  name: 'WYAEvents',
  title: ' | WYA Events',
  data() {
    return {
      getReadableEventTarget,
      loading: true,
      events: [],
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn
    }
  },
  async mounted() {
    window.scrollTo(0, 0)
    await this.fetchData();
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    async fetchData() {
      const now = new Date();
      const today = this.$options.filters.dateFormat(now, "YYYY-MM-DD");
      try {
        const res = await Api.platformEvent({ is_active: true, end_date__gte: today, ordering: "-start_date" });
        this.events = res?.data?.results || [];
      } catch (ex) {
        console.error(ex.message);
      }
    },
    toggleBurgerButtonClass(addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    async applyEvent(event) {
      try {
        const res = await Api.applyPlatformEvent(event.id);
        event.already_apply = true;
        const message = res.data.message;
        this.$bvToast.toast(message, {
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
          appendToast: true,
          noCloseButton: false,
          toastClass: 'custom-toast',
        });
      } catch (ex) {
        const error = ex.message;
        if(ex?.response?.data?.message) {
          error = ex.response.data.message;
        }
        this.$bvToast.toast(error, {
          title: 'Error',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
          appendToast: true,
          noCloseButton: false,
          toastClass: 'custom-toast',
        });
      }
    }
  },
  destroyed() {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
.event-title {
  font-size: 100px;
  letter-spacing: 30px;
}

@media screen and (max-width: 767.98px) {
  .event-title {
    font-size: 50px;
  }
}
</style>
